import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import styles from "./Stats.module.scss";
import Filter from "../../Common/Filter/Filter";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import {
  getRecentActivity,
  openPopUp,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  sliceChar,
} from "../../../Helper/common";
import {
  DECIMAL,
  DROP_TYPE,
  MARKET_TYPE,
  PER_PAGE_RECORD_LIMIT,
} from "../../../Constant/userConstant";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import personIcon from "../../../Assets/images/person_icon.jpg";
import { Helmet } from "react-helmet";

const filterOption = [
  { label: "OLDEST", value: "1" },
  { label: "NEWEST", value: "0" },
];

const RecentActivity = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.users?.profileData?.id);
  const [reportsListData, setReportsListData] = useState([]);
  const [timeRange, setTimeRange] = useState("ALL");
  const [selectedOption, setSelectedOption] = useState(0);
  const [paginationData, setPaginationData] = useState({});
  const navigate = useNavigate();
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const pageRecentActivity = useSelector(
    (state) => state?.users?.pageRecentActivity
  );
  useEffect(() => {
    getRecentActivityHandler();
  }, [userId, selectedOption, timeRange, pageRecentActivity]);

  useEffect(() => {
    // Reset timeRange to 1 when the user logs in

    if (isUserLoggedin) {
      setTimeRange("ALL");
      setSelectedOption(0);
    }
  }, [isUserLoggedin]);

  const getRecentActivityHandler = async () => {
    try {
      let params = {
        filter: selectedOption,
        duration: timeRange,
        page: pageRecentActivity,
        size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
      };
      let response = await dispatch(getRecentActivity(params));
      setPaginationData(response?.data);
      setReportsListData(response?.data?.data);
    } catch (err) {}
  };

  const formatDuration = (createdAt) => {
    const now = moment();
    const start = moment(createdAt);
    const duration = moment.duration(now.diff(start));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const seconds = Math.floor(duration.asSeconds()) % 60;

    return `${hours}Hrs ${minutes}Min ${seconds}Sec`;
  };

  // Common onClick handler for navigating to the user's profile page to user
  const navigateToUserProfile = (username) => {
    navigate(`/profile/${username}`);
  };

  // Navigate to the marketplace art page when Make Offer button is clicked
  const makeOfferButtonRedirection = (item) => {
    if (isUserLoggedin) {
      navigate(`/marketplace/art/${item.art_id}`);
    } else {
      dispatch(openPopUp("login"));
      dispatch(saveNextRoute(`/marketplace/art/${item.art_id}`));
    }
  };

  const filterHandleOption = (e) => {
    setSelectedOption(e.value);
  };

  // Determine if "Make Offer" button should be enabled
  const getMakeOfferStatus = (item) => {
    // userId trigger if user is loggedIn or not
    let otherEditionCount = item.sold_no_of_editions - item.artCount;
    // if(item.mar)
    if (item.marketplace_type === MARKET_TYPE.PRIMARY) {
      return false;
    } else if (
      item.marketplace_type === MARKET_TYPE.SECONDARY &&
      item.activity_type == "LIST"
    ) {
      return true;
    } else if (
      item.marketplace_type === MARKET_TYPE.SECONDARY &&
      item.activity_type == "SALE"
    ) {
      return true;
    } else if (otherEditionCount == 0) {
      return false;
    } else {
      return true;
    }
  };
  // Determine if "Buy Now" button should be shown
  const getShowHideBuyNowButton = (item) => {
    if (item.marketplace_type === MARKET_TYPE.PRIMARY) {
      return false;
    } else if (
      item.marketplace_type === MARKET_TYPE.SECONDARY &&
      item.activity_type == "LIST" &&
      item.from_user_id !== userId
    ) {
      return true;
    }
  };

  // Determine if "Bid" button should be shown
  const getShowHideBidButton = (item) => {
    if (
      item.marketplace_type === MARKET_TYPE.PRIMARY &&
      (item.drop_type == DROP_TYPE.AUCTION ||
        item.drop_type == DROP_TYPE.RANKED_AUCTION ||
        item.drop_type == DROP_TYPE.RAFFLES)
    ) {
      return true;
    }
  };

  // Navigate to art details page based on drop status, start time and end time
  const artNavigation = async (item) => {
    let type;
    if (item.drop_type != 3) {
      type = determineEventStatus(item.start_time, item.end_time);
    } else {
      type = determineEventStatusOfFCFB(item);
    }
    if (type === "previous") {
      navigate(`/marketplace/art/${item.art_id}`);
    } else {
      navigate(
        `/curated-drop/digital-art/${
          item.drop_type != 3
            ? determineEventStatus(item.start_time, item.end_time)
            : determineEventStatusOfFCFB(item)
        }/${item.art_id}`
      );
    }
  };

  const renderFromDivSection = (item) => {
    const imagePath =
      item.activity_type === "BID"
        ? item?.to_user_image_path
        : item?.from_user_image_path;
    const username =
      item.activity_type === "BID" ? item?.to_username : item?.from_username;

    const userId =
      item.activity_type === "BID" ? item?.to_user_id : item?.from_user_id;

    return (
      <div className="artDetails">
        <img
          src={
            imagePath === ""
              ? personIcon
              : `${process.env.REACT_APP_IMG_BASE_URL}${imagePath}`
          }
          alt="artimage"
          className="personIcon"
        />
        <div className="artDetails_textSec cursor-pointer">
          <p title={username} onClick={() => navigateToUserProfile(username)}>
            @{sliceChar(username, 15)}
          </p>
        </div>
      </div>
    );
  };

  const renderToDivSection = (item) => {
    const imagePath =
      item.activity_type === "BID"
        ? item?.from_user_image_path
        : item?.to_user_image_path;
    const username =
      item.activity_type === "BID"
        ? item?.from_username
        : item.activity_type === "SALE" && item?.to_username === ""
        ? "Offline Sale"
        : item?.to_username;

    const userId =
      item.activity_type === "BID" ? item?.from_user_id : item?.to_user_id;

    return (
      <div className="d-flex align-items-center personIcon-outer artDetails">
        <img
          src={
            item.to_username === "" && item.activity_type === "SALE" //offline sale profile image check condition
              ? personIcon
              : imagePath === ""
              ? personIcon
              : `${process.env.REACT_APP_IMG_BASE_URL}${imagePath}`
          }
          alt="artimage"
          className="personIcon"
        />
        <div className="text_field cursor-pointer artDetails_textSec">
          {item.activity_type === "SALE" && item?.to_username === "" ? (
            <p title={username}>{sliceChar(username, 15)}</p>
          ) : (
            <p title={username} onClick={() => navigateToUserProfile(username)}>
              @{sliceChar(username, 15)}
            </p>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <section className={styles.stats}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Container>
          <div className={styles.statsHead}>
            <Row className="align-items-center">
              <Col md={6}>
                <h2 className="text-center mb-md-0 mb-3 text-md-start">
                  Activity
                </h2>
              </Col>
              <Col md={6}>
                <div className="text-center text-md-end">
                  <Filter
                    className="text-md-start"
                    setTimeRange={setTimeRange}
                    timeRange={timeRange}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className={`spacesTables ${styles.statsTable}`}>
            <CommonTable>
              <thead>
                <tr>
                  <th className="selectThead">
                    <Select
                      value={selectedOption}
                      onChange={(e) => {
                        filterHandleOption(e);
                      }}
                      options={filterOption}
                      placeholder={selectedOption == 0 ? "NEWEST" : "OLDEST"}
                      classNamePrefix="react-select"
                      className="react-select"
                      // menuIsOpen
                    />
                  </th>
                  <th>
                    <span></span>
                  </th>
                  <th>
                    <span>Digital Fine Art</span>
                  </th>
                  <th>
                    <span>Price</span>
                  </th>
                  <th>
                    <span>From</span>
                  </th>
                  <th>
                    <span>To</span>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              {reportsListData.length > 0 ? (
                <tbody>
                  {reportsListData.map((item, index) => (
                    <tr key={index}>
                      {/* <td><div className="d-flex align-items-center thumb-outer"><img className="thumb" src={item.thumb} alt="thumb" /> <h4>{item.title}</h4></div></td> */}

                      <td>{formatDuration(item.createAt)}</td>

                      <td>{item.activity_type}</td>
                      {/* artist user profile */}

                      <td>
                        <div className="digitalArt">
                          <img
                            src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.art_image}`}
                            alt="artimage"
                            className="clickable"
                            onClick={() => artNavigation(item)}
                          />
                          <div className="digitalArt_textSec cursor-pointer">
                            <h5 title={item.art_name}>
                              {sliceChar(item.art_name, 10)}
                            </h5>
                            <p
                              title={item.artist_name}
                              onClick={() =>
                                navigateToUserProfile(item.artist_name)
                              }
                            >
                              @{sliceChar(item.artist_name, 15)}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td>₹{(item.price / DECIMAL).toFixed(2)}</td>
                      {/* artist user or other profile */}

                      <td>{renderFromDivSection(item)}</td>
                      {/* artist user or other2 profile */}

                      <td>
                        {item.activity_type !== "GLOBAL OFFER" &&
                          item.activity_type !== "LIST" &&
                          renderToDivSection(item)}
                      </td>
                      <td>
                        {getMakeOfferStatus(item) && (
                          <CommonBtn
                            role="btn"
                            title="Make Offer"
                            onClick={() => makeOfferButtonRedirection(item)}
                          />
                        )}

                        {getShowHideBuyNowButton(item) && (
                          <CommonBtn
                            role="btn"
                            title="Buy Now"
                            onClick={() => makeOfferButtonRedirection(item)}
                            className="ms-4"
                          />
                        )}

                        {getShowHideBidButton(item) && (
                          <CommonBtn
                            role="btn"
                            title={
                              item.drop_type === 1 || item.drop_type === 2
                                ? `Place Bid`
                                : item.drop_type === 5 && "Buy Entry"
                            }
                            onClick={() => artNavigation(item)}
                            className="ms-4"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={8} className="text-center">
                      No data found
                    </td>
                  </tr>
                </tbody>
              )}

              {/* {reportsListData.length == 0 && (
                <tr>
                  <td colSpan={8}>
                    <p className="no_recordtable">No records found</p>
                  </td>
                </tr>
              )} */}
            </CommonTable>
            {paginationData?.totalPages > 1 && (
              <CustomPagination
                className="ms-auto"
                activePage={pageRecentActivity}
                totalPage={paginationData?.totalPages}
                maxVisiblePages={5}
                dispatch={dispatch}
                componentType={"RecentActivity"}
              />
            )}
          </div>
        </Container>
      </section>
    </>
  );
};
export default RecentActivity;
