import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import styles from "./FlauntPage.module.scss";
import NewArtApp from "../../../Assets/images/NewArtApp.png";
import Mobilefault from "../../../Assets/images/Mobilefault.png";
import Androidbtn from "../../../Assets/images/Androidbtn.png";
import AppstoreBtn from "../../../Assets/images/AppstoreBtn.png";
import naxCanvasMain from "../../../Assets/images/Nax Canvas.png";
import { APP_LINK } from "../../../Constant/userConstant";
import { Helmet } from "react-helmet";

const FlauntPage = () => {
  const handleRedirect = () => {
    window.open(APP_LINK, "_blank");
  };

  return (
    <>
      <section className={styles.FlauntBanner}>
        <Helmet>
          <title>Display Your NFTs in Style with NAXCanvas | NewartX</title>
          <meta
            name="description"
            content="Introducing NAXCanvas, the art screen specifically designed to showcase your NFTs (DFAs) in all their glory."
          />
        </Helmet>
        <Container>
          <h3 className={styles.flauntHead}>
            Ways to Flaunt Your Digital Fine Art
          </h3>
          <div className={styles.FlauntBannerText}>
            <p>
              Flaunting your digital fine art has never been this seamless and
              elegant. With NewArtX, you can curate, showcase, and immerse
              yourself in the world of digital art effortlessly. Elevate your
              artistic expression and share your passion with the world through
              the NewArtX app and the stunning NAX Canvas.­­­­
            </p>
          </div>
        </Container>
      </section>
      <section className={styles.GetAppSec}>
        <Container>
          <Row className="align-items-center">
            <Col sm={6}>
              <div className={styles.getAppText}>
                <div className={styles.getAppTextHeadlogo}>
                  <h2>Get The</h2>
                  <img src={NewArtApp} alt="" />
                </div>
                <div className={styles.getAppTextMaintext}>
                  <p>
                    “NewArtX, a cutting-edge platform dedicated to Digital Fine
                    Art, offers a seamless and captivating way to flaunt your
                    DFAs on various devices, including Android and iOS devices
                    such as tablets, mobile phones, and even TVs.­­­{" "}
                    <span>
                      Beginning your journey into the world of Digital Fine Art
                      appreciation is easy, all you have to do is download the
                      NewArtX app, available for both Android and iOS platforms.
                      Once installed, users can login to their NewArtX accounts
                      and gain access to their purchased DFAs. The app provides
                      an intuitive and user-friendly interface, allowing you to
                      effortlessly browse through your digital art collection.”
                    </span>
                  </p>
                </div>
              </div>
              <CommonBtn
                role="link"
                title="Download App"
                className=""
                target="_blank"
                to={APP_LINK}
              />
            </Col>
            <Col sm={6} className="text-center">
              <img src={Mobilefault} className="mt-sm-0 mt-4" alt="" />
              <div className={styles.btnAppins}>
                <button
                  className={`me-3 ${styles.btnApp}`}
                  onClick={handleRedirect}
                >
                  <img src={Androidbtn} alt="btnApp" />
                </button>
                <button className={`ms-3 ${styles.btnApp}`}>
                  <img src={AppstoreBtn} alt="btnApp" />
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.DigitalSec}>
        <Container>
          {/* <Row className='align-items-center justify-content-between'>
                        <Col xxl={7} md={7}>
                            <div className={styles.DigitalSecScreens}>
                                <img src={tvScreen} alt="" />
                                <img src={laptop} alt="" />
                                <img className={styles.tab} src={tab} alt="" />
                            </div>
                        </Col>
                        <Col md={4}>
                            <h2 className="big">Digital <br /> Display <br /> Screens</h2>
                            <CommonBtn role='btn'
                                title='Explore'
                                icon={<ArrowRight />}
                                className="btn-yellow"
                            />
                        </Col>
                    </Row> */}
          <div className={styles.DigitalSecInnerpart}>
            <h3>Digital Display Screens</h3>
            <img src={naxCanvasMain} alt="naxCanvasMain" />
            <div className={styles.DigitalSecInnerpartTextSec}>
              <p>
                What sets NewArtX apart is its commitment to enhancing the
                viewing experience of DFAs. For those seeking a premium showcase
                for their digital masterpieces, NewArtX introduces the NAX
                Canvas. This specially manufactured Art screen is designed to
                showcase your DFAs in all their glory, ensuring that each stroke
                and detail is presented with unparalleled clarity and vibrancy.
              </p>
              <p>
                The NAX Canvas is not just a display; it’s a testament to the
                fusion of technology and artistry. Crafted to complement the
                aesthetics of any space, the NAXCanvas adds a touch of
                sophistication to your surroundings. Whether placed in your
                living room, office, or personal art studio, the NAX Canvas
                transforms any space into a dynamic gallery, bringing your DFAs
                to life in a way they truly deserve.
              </p>
            </div>
            {/* <CommonBtn role='link' to='https://naxcanvas.com/' title="Buy Now" className='' /> */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default FlauntPage;
