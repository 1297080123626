import React from "react";
import style from "./AboutNewArtX.module..scss";
import BenefitsNewart from "./BenefitsNewart/BenefitsNewart";
import NewArtXBanner from "./NewArtXBanner/NewArtXBanner";
import { Helmet } from "react-helmet";

const AboutNewArtX = () => {
  return (
    <>
      <section className={style.aboutNewart}>
        <Helmet>
          <title>About NewartX - a curated Digital Fine Art platform</title>
          <meta
            name="description"
            content="NewartX is a digital fine art platform that connects art enthusiasts with a curated selection of original artwork from talented artists. Discover unique digital fine art to enhance your home or office."
          />
        </Helmet>
        <NewArtXBanner />
        <BenefitsNewart />
      </section>
    </>
  );
};

export default AboutNewArtX;
