import React, { useEffect, useState } from "react";
import CommonModal from "../CommonModal/CommonModal";
import FormikControls from "../Formik/FormikControls";
import searchIcon from "../../../../Assets/icons/search-icon.png";
import styles from "./SearchModal.module.scss";
import { Col, Row, Spinner } from "react-bootstrap";
import SearchItem from "./SearchItem/SearchItem";
import closeIcon from "../../../../Assets/icons/closeIcon-green.png";
import CommonBtn from "../CommonBtn/CommonBtn";
import { useDebounce } from "../../../../customHooks/useDebounce";
import {
  getSearchedRecords,
  setMarketplaceFilter,
} from "../../../../Features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import personIcon from "../../../../Assets/images/person_icon.jpg";
import { useNavigate } from "react-router-dom";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
} from "../../../../Helper/common";
import Notfound from "../../NotFound/Notfound";
import { COMPARISION_CONSTANT_NAMES } from "../../../../Constant/userConstant";
const SearchModal = (props) => {
  let { searchTerm, setSearchTerm } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [artList, setArtList] = useState([]);
  const [artistList, setArtistList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [loader, setLoader] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (
      debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
      debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE
    ) {
      if (props.show) {
        getAllSearchedRecordsHandler();
      }
    }
  }, [debouncedSearchTerm, props.show]);

  const getAllSearchedRecordsHandler = async () => {
    let data = {
      searchText: debouncedSearchTerm.trim(),
    };
    if (debouncedSearchTerm.length === 0) {
      delete data.searchText;
    }
    try {
      setLoader(true);
      const response = await dispatch(getSearchedRecords(data));
      setLoader(false);
      setArtList(response.data.nftArts);
      setArtistList(response.data.artistList);
      // setDropList(response.data.nftDrops);
      setTagList(response?.data?.tags);
      setUserList(response.data.userList);
    } catch (err) {}
  };

  const userNavigation = (item) => {
    props.onHide();
    setArtList([]);
    setArtistList([]);
    setDropList([]);
    setUserList([]);
    setTagList([]);
    navigate(`/profile/${item?.username}`);
  };

  const artNavigation = async (item) => {
    props.onHide();
    setArtList([]);
    setArtistList([]);
    setDropList([]);
    setUserList([]);
    setTagList([]);
    let type;
    if (item.drop_type != 3) {
      type = determineEventStatus(item.start_time, item.end_time);
    } else {
      type = determineEventStatusOfFCFB(item);
    }
    if (type === "previous") {
      navigate(`/marketplace/art/${item.id}`);
    } else {
      navigate(
        `/curated-drop/digital-art/${
          item.drop_type != 3
            ? determineEventStatus(item.start_time, item.end_time)
            : determineEventStatusOfFCFB(item)
        }/${item.id}`
      );
    }
  };

  const dropNavigation = (item) => {
    props.onHide();
    setArtList([]);
    setArtistList([]);
    setDropList([]);
    setUserList([]);
    setTagList([]);
    navigate(`/drop/${item.id}/all`);
  };

  const tagNavigation = async (item) => {
    props.onHide();
    setArtList([]);
    setArtistList([]);
    setDropList([]);
    setUserList([]);
    setTagList([]);
    {
      let tagsItem = [item.tag_name];
      await dispatch(setMarketplaceFilter({ type: "tags", data: tagsItem }));
      navigate("/marketplace");
    }
  };
  return (
    <div>
      <CommonModal
        className={`${styles.searchModal}`}
        // className={`${isUserLoggedin ? `` : `${styles.loggedout_searchmodal}`}
        //     ${styles.searchModal}`}
        show={props.show}
        onHide={props.onHide}
        loader={loader}
      >
        <FormikControls
          leftIcon={<img src={searchIcon} alt="search" />}
          control="input"
          type="text"
          name="search"
          placeholder="Search by Art, Artist, Tags, Series, Collector, Drop"
          onChange={(e) => setSearchTerm(e.target.value)}
          variant={styles.SearchInput}
          autoFocus={true}
        />
        {loader && (
          <>
            <div className="loader_style">
              <Spinner animation="border" variant="primary" />
            </div>
          </>
        )}
        <CommonBtn
          title={<img src={closeIcon} alt="cross icon" />}
          role="btn"
          className={styles.closeBtn}
          type="reset"
          onClick={() => {
            props.onHide();
            setArtList([]);
            setArtistList([]);
            setDropList([]);
            setUserList([]);
            setSearchTerm("");
          }}
        />

        {(artList.length > 0 ||
          artistList.length > 0 ||
          dropList.length > 0 ||
          userList.length > 0) && (
          <div className={styles.categories}>
            <Row className="justify-content-between">
              {artList.length > 0 && (
                <Col xl={3} lg={3} md={4} sm={6}>
                  <h3>Art</h3>
                  <div className={styles.categoryBox}>
                    {artList?.map((item) => (
                      <SearchItem
                        key={item.id}
                        name={item.art_name}
                        icon={
                          item?.art_image
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`
                            : personIcon
                        }
                        artistName={`@${item?.art_user?.username}`}
                        redirectionClick={() => artNavigation(item)}
                      />
                    ))}
                  </div>
                </Col>
              )}
              {artistList.length > 0 /* && isUserLoggedin */ && (
                <Col xl={3} lg={3} md={4} sm={6}>
                  <h3>Artist</h3>
                  <div className={styles.categoryBox}>
                    {artistList.map((item) => (
                      <SearchItem
                        key={item.id}
                        name={`${item.first_name} ${item.middle_name} ${item.last_name}`}
                        icon={
                          item?.image_path
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item.image_path}`
                            : personIcon
                        }
                        artistName={`@${item.username}`}
                        redirectionClick={() => userNavigation(item)}
                      />
                    ))}
                  </div>
                </Col>
              )}
              {/* {dropList.length > 0 && (
                <Col xl={3} lg={3} md={4} sm={6}>
                  <h3>Drop</h3>
                  <div className={styles.categoryBox}>
                    {dropList.map((item) => (
                      <SearchItem
                        key={item.id}
                        name={item.drop_name}
                        icon={
                          item?.feature_image
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item.feature_image}`
                            : personIcon
                        }
                        artistName={`@${item?.register_list?.username}`}
                        redirectionClick={() => dropNavigation(item)}
                      />
                    ))}
                  </div>
                </Col>
              )} */}

              {/* {tagList?.length > 0 && (
                <Col xl={3} lg={3} md={4} sm={6}>
                  <h3>Tag</h3>
                  <div className={styles.categoryBox}>
                    {tagList.map((item) => (
                      <SearchItem
                        key={item.id}
                        tagname={item.tag_name}
                        redirectionClick={() => tagNavigation(item)}
                      />
                    ))}
                  </div>
                </Col>
              )} */}
              {userList.length > 0 /* && isUserLoggedin */ && (
                <Col xl={3} lg={3} md={4} sm={6}>
                  <h3>Collector</h3>
                  <div className={styles.categoryBox}>
                    {userList.map((item) => (
                      <SearchItem
                        key={item.id}
                        // name={`${item.first_name} ${item.middle_name} ${item.last_name}`}
                        icon={
                          item?.image_path
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item.image_path}`
                            : personIcon
                        }
                        artistName={`@${item.username}`}
                        redirectionClick={() => userNavigation(item)}
                      />
                    ))}
                  </div>
                </Col>
              )}
            </Row>
          </div>
        )}
        {/* {isUserLoggedin && ( */}
        {/* <> */}
        {artistList.length == 0 &&
          userList.length == 0 &&
          dropList.length == 0 &&
          artList.length == 0 && <Notfound text={"No data found."} />}
        {/* </> */}
        {/* )} */}
      </CommonModal>
    </div>
  );
};
export default SearchModal;
